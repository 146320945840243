import { compose, converge, reduce, map } from '@/services/utility/fp.utility'
import { path, pick, merge, fromTruthy } from '@/services/utility/object.utility'
import { isNil } from '@/services/utility/predicate.utility'

/**
 * Point free Object.values
 *
 * @func
 * @param {Object} object
 * @return {Array<*>}
 * */
const values = object => Object.values(object)

/**
 * Evaluate any given value as a boolean
 *
 * @func
 * @param {*} val
 * @return {Boolean}
 *
 * @example makeBool('') // -> false
 * @example makeBool('foo') // -> true
 * @example makeBool(0) // -> true
 * @example makeBool(1) // -> true
 * */
export const makeBool = val => !!(val)

/**
 * Determine if a list of values are all truthy
 *
 * @func
 * @param {Array<*>}
 * @return {Boolean}
 *
 * @example isValid([0, 1, 2, 3, 4, 5, 6]) // -> false
 * @example isValid([1, 2, 3, 4, 5, 6]) // -> true
 * @example isValid(['foo', 'bar', 'baz', '']) // -> false
 * @example isValid(['foo', 33, { a: null }, true]) // -> true
 * @example isValid(['foo', 33, null, true]) // -> false
 * */
export const isValid = compose(
  reduce((a, c) => a && c, true),
  map(makeBool),
  values
)

/**
 * Determine if a given object contains any `null` values
 *
 * @func
 * @param {Object} customerInfo
 * @return {Boolean}
 *
 * @example fromEmpty({ firstName: null }) // -> true
 * @example fromEmpty({ firstName: '' }) // -> false
 * */
export const fromEmpty = compose(
  isNil,
  reduce((a, c) =>
    c === null
      ? a === null ? c : a
      : c,
  null
  ),
  values
)

/**
 * Create a new object from a given object's `serviceType`, `orderDueAt` and `isAsap` properties
 *
 * @func
 * @param {Object} orderInfo
 * @return {Object} orderInfo
 * */
const getTopLevel = pick(['serviceType', 'orderDueAt', 'isAsap'])

/**
 * Create a new object from a given object's `storeInfo.storeId` path
 *
 * @func
 * @param {Object}
 * @return {Object}
 * */
const getStoreId = compose(
  pick(['storeId']), // pick property and return object
  path(['storeInfo'])
)

/**
 * Create a new object from a given object's `customerInfo` path
 *
 * @func
 * @param {Object}
 * @return {Object}
 * */
export const getAddressInfo = compose(
  pick(['address', 'city', 'state', 'postalCode', 'longitude', 'latitude']),
  path(['customerInfo'])
)

/**
 * Create a new `orderInfo` object from a given object with `serviceType`, `orderDueAt`, `isAsap` and `storeInfo.storeId` properties
 * Merges the output from `getTopLevel` and `getStoreId` into a single object
 *
 * @func
 * @param {Object}
 * @return {Object}
 * */
const getOrderInfo = converge(
  merge,
  [getTopLevel, getStoreId]
)

/**
 * Create a new coordinates object from a given object's `customerInfo` path
 *
 * @func
 * @param {Object}
 * @return {Object}
 * */
export const getCoordinates = compose(
  pick(['longitude', 'latitude'])
)

/**
 * Create a new `orderDetails` object from a given object
 * Merges the output from `getAddressInfo` and `getOrderInfo` into a single object
 *
 * @func
 * @param {Object}
 * @return {Object}
 * */
export const create = converge(
  merge,
  [getOrderInfo, getAddressInfo]
)

/**
 * Map `basket.customerInfo` ==> `accountProfile` for Customer API
 *
 * @func
 * @param {object}
 * @returns {Object}
 */
export const toAccount = ({ phoneNumber: phone, emailAddress: email, ...customerInfo }) => ({ mobileNumber: phone, email, ...customerInfo })

/**
 * Map `accountProfile` for Customer API ==> `basket.customerInfo`
 *
 * @func
 * @param {object}
 * @returns {Object}
 */
export const fromAccount = ({ mobileNumber: phone, email, ...customerInfo }) => ({ phoneNumber: phone, emailAddress: email, ...customerInfo })

/**
 * Transform a given object into an expected shape
 *
 * @func
 * @param {object}
 * @return {Object}
 * */
export const getCustomerInfo = pick(['firstName', 'lastName', 'phoneNumber', 'emailAddress', 'optinSms', 'optinEmail', 'optinSupercarsFan', 'customerId', 'userId'])

/**
 * Merge two objects with similar values into a single uniform shape
 * Intended to merge `accountProfile` and `basket.customerInfo` data into a single object consumable by the API
 *
 * @func
 * @param {Object} accountProfile
 * @param {Object} basketProfile - `basket.customerInfo`
 * @return {Object}
 * */
export const makeUserProfile = (accountProfile, basketProfile) => getCustomerInfo(merge(fromAccount(accountProfile), fromTruthy(basketProfile)))
