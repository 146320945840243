import { curry } from '@/services/utility/fp.utility'
export const capitalize = string => {
  if (!string || typeof string !== 'string') {
    return
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeEach = strings => {
  if (!strings || typeof strings !== 'string') {
    return
  }

  return strings.split(' ')
    .map(string => string.charAt(0).toUpperCase() + string.slice(1))
    .join(' ')
}
export const dekebab = string => {
  if (!string || typeof string !== 'string') {
    return
  }

  return string.replace(/-/g, ' ')
}

export const desnake = string => {
  if (!string || typeof string !== 'string') {
    return
  }

  return string.replace(/_/g, ' ').trim()
}

/**
 * Transform Regex to String Pattern
 * @func
 * @param {Regexp} rx
 * @return {String}
 * */
export const regexToPattern = rx => rx.toString().split('/').filter(x => x)[0]

/**
 * Join a list into a String
 * @func
 * @curried
 * @param {String} character
 * @param {Array<*>} array
 * @return {String}
 * */
export const join = curry((c, a) => a.join(c))

/**
 * Split a String into a List
 * @func
 * @curried
 * @param {String} character
 * @param {String} string
 * @return {Array<String>}
 * */
export const split = curry((c, s) => s.split(c))

/**
 * Point-free toLowerCase Helper
 * @func
 * @param {string}
 * @return {string}
 * */
export const toLowerCase = string => string.toLowerCase()

/** Splits a camel-case or Pascal-case variable name into individual words
 * @param {string} string
 * @returns {string}
 */
export const splitWords = string => {
  const splitWords = []; let regEx = []; let match = []

  regEx = /([A-Za-z]?)([a-z]+)/g

  match = regEx.exec(string)
  while (match) {
    splitWords.push([match[1].toUpperCase(), match[2]].join(''))
    match = regEx.exec(string)
  }

  return splitWords.join(' ')
}

/** Trim and removes line breaks from string
 * @param {string} string
 * @returns {string}
 */
 export const trimRemoveLineBreaks = string => {
  return string.trim().replace(/\r?\n|\r/g, '')
}

/** Remove all non numeric characters except plus sign
 * @param {string} string
 * @returns {string}
 */
 export const removeNonNumericExceptPlus = string => {
  if (!string || typeof string !== 'string') {
    return
  }

  return string.replace(/[^0-9+]/g, '')
}

export const toSnakeCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .toLowerCase()
}

/**
 * Match all characters and symbols except for letters in the English alphabet,
 * digits from 0 to 9, comma, colons, semicolons, dash, dot, question mark,
 * exclamation mark, and blank space:
 */
export const removeSpecialCharacters = (str) => {
  return str.replace(/[^a-zA-Z0-9,:;\-.?! ]/g, '')
}

export const isValidUrl = (string) => {
  try {
    return Boolean(new URL(string))
  } catch (err) {
    return false
  }
}

export const singularize = (word) => {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: ''
  }

  return word.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`), r => endings[r]
  )
}

export const pluralize = (word, amount) => {
  return amount > 1 ? `${word}s` : word
}

export const camelToPascalCase = (string) => {
  if (!string) return

  const result = string.replace(/([A-Z])/g, '$1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const zeroFill = (value) => {
  return (value < 10 && value > -1 ? '0' : '') + value
}
