import ContentApi from '@/services/api/content.api'
import dayjs from 'dayjs'
import { CacheController } from '@/controllers/cache.controller'
import { ContentController } from '@/controllers/content.controller'
import { bottomNavDefaults } from '@/content/bottom-nav.default.content'
import { config } from '@/config/global.config'
import { defineStore } from 'pinia'
import { filterScheduledItem, filterStories, removeScheduleQueryPath } from '@/services/utility/storyblok.schedule.utility.js'
import { footerDefaults } from '@/content/footer.default.content'
import { getIconFromFileName } from '@/services/utility/bottom-nav.utility'
import { replaceImageDomain } from '@/services/utility/storyblok.image.utility'

import { useCacheStore } from '@/stores/cache'

export const useContentStore = defineStore('content', {
  state: () => ({
    version: '',
    splash: {
      name: '',
      active: false,
      content: {},
      timestamp: null,
      dismissals: []
    },
    blacklistedUserAgents: [],
    blacklistedIRPID: []
  }),

  getters: {
    splashActive: state => state.splash.active,
    splashLocked: state => state.splash.dismissals.length < config.SPLASH.MAX_DISMISSALS,
    splashExpired: state => {
      const timestamp = state.splash.timestamp
      const expiry = dayjs(timestamp).add(config.SPLASH.EXPIRY.toString(), 'minute')
      const isExpired = expiry.isSameOrBefore(dayjs())
      return timestamp && isExpired
    },
  },

  actions: {
    toggleSplash ({ active, content, name }) {
      const payload = {
        name,
        active,
        content,
        dismissed: !active,
        dismissals: this.splash.dismissals,
        timestamp: this.splash.timestamp
      }

      if (this.splashExpired) {
        payload.dismissals = []
        payload.timestamp = null
      } else if (this.splashLocked || payload.dismissals.includes(name)) {
        return
      }

      if (!active) {
        payload.name = ''
        if (name) {
          payload.dismissals = [...payload.dismissals, name]
        }
        payload.timestamp = dayjs().format()
      }

      this.splash = payload
    },

    async getSpace () {
      const response = await ContentApi.GetSpace()
      const { space } = response.data
      this.version= space.version
      return space
    },

    async getContentBySlug (payload) {
      const response = await ContentApi.GetContentBySlug(payload.slug, this.version)
      return response.data
    },

    async getContentCampaignBySlug (payload) {
      const response = await ContentApi.GetContentCampaignBySlug(payload.slug, this.version)
      return response.data
    },

    async getCampaignBySlug (payload) {
      const response = await ContentApi.GetCampaignBySlug(payload, this.version)
      return response.data.stories?.[0]?.content
    },

    async getCokeCampaign (payload) {
      const response = await ContentApi.GetCokeCampaign(payload, this.version)
      return {
        ...response.data.stories?.[0]?.content,
        slug: response.data.stories?.[0]?.slug
      }
    },

    async getBlogPosts (payload) {
      const response = await ContentApi.GetBlogPosts(payload, this.version)
      return {
        perPage: response.perPage,
        total: response.total,
        stories: response.data?.stories
      }
    },

    async getBlogPostsByCategory (payload) {
      const response = await ContentApi.GetBlogPostsByCategory(payload, this.version)
      return response.data
    },

    async getBlogPostBySlug (payload) {
      const response = await ContentApi.GetBlogPostBySlug(payload.slug, this.version)
      return response.data
    },

    async getOffers (payload) {
      try {
        const response = await ContentApi.GetOffers(payload.query, this.version)
        const stories = filterStories(response.data.stories)
        const content = stories?.at(0)?.content
        if (!content) return null

        // Filter carousel items according to scheduling rules
        content.carousel_items = content.carousel_items.filter(carouselItem => filterScheduledItem(carouselItem, payload.query, ['schedule', '0']))

        // convert tags attribute to array
        content.carousel_items.forEach(element => {
          if (element.tags) {
            const tagArray = element.tags.split(',')
            element.tags = []
            tagArray.forEach(t => {
              const tag = {}
              tag.name = t.trim()
              tag.id = t.toLowerCase().trim()
              tag.type = 'tag'
              tag.active = false
              element.tags.push(tag)
            })
          } else {
            element.tags = []
          }
        })

        if (payload.storeInCache) {
          useCacheStore().setCache({
            expires_at: CacheController.getTimeInXMinutes(Number(content.cache_expiry)),
            cache: content,
            cacheType: 'offers'
          }, {
            root: true
          })
        }

        return stories[0].content
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    async getCoupons (payload) {      try {
        const response = await ContentApi.GetCoupons(payload.query, this.version)
        const stories = filterStories(response.data.stories)
        const content = stories?.at(0)?.content
        if (!content) return null

        // Filter carousel items according to scheduling rules
        content.carousel_items = content.carousel_items.filter(carouselItem => filterScheduledItem(carouselItem, payload.query, ['schedule', '0']))

        // convert tags attribute to array
        content.carousel_items.forEach(element => {
          if (element.tags) {
            const tagArray = element.tags.split(',')
            element.tags = []
            tagArray.forEach(t => {
              const tag = {}
              tag.name = t.trim()
              tag.id = t.toLowerCase().trim()
              tag.type = 'tag'
              tag.active = false
              element.tags.push(tag)
            })
          } else {
            element.tags = []
          }
        })

        if (payload.storeInCache) {
          useCacheStore().setCache({
            expires_at: CacheController.getTimeInXMinutes(Number(content.cache_expiry)),
            cache: content,
            cacheType: 'coupons'
          }, {
            root: true
          })
        }
        return stories[0].content
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    async getBanner (payload) {
      try {
        const response = await ContentApi.GetBanner(payload, this.version)
        const stories = filterStories(response.data.stories)

        const content = stories.at(0)?.content
        const expiryMins = Number(content?.cache_expiry ?? config.CACHE_DURATION_IN_MINUTES)

        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(expiryMins),
          cache: content,
          cacheType: 'banner'
        }, {
          root: true
        })

        return content
      } catch (error) {
        return error
      }
    },

    async getBannerSecondary (payload) {
      try {
        const response = await ContentApi.GetBannerSecondary(payload, this.version)

        // Check if we will show on specific browser or device
        const stories = filterStories(response.data.stories)

        const content = stories.at(0)?.content
        const expiryMins = Number(content?.cache_expiry ?? config.CACHE_DURATION_IN_MINUTES)

        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(expiryMins),
          cache: content,
          cacheType: 'banner_secondary'
        }, {
          root: true
        })

        return content
      } catch (error) {
        return error
      }
    },

    async getBannerSub (payload) {
      try {
        const response = await ContentApi.GetBannerSub(payload, this.version)

        // Check if we will show on specific browser or device
        const stories = filterStories(response.data.stories)

        const content = stories.at(0)?.content

        // Filter carousel items according to scheduling rules
        content.carousel_items = content.carousel_items.filter(carouselItem => filterScheduledItem(carouselItem, payload, ['schedule', '0']))

        const expiryMins = Number(content?.cache_expiry ?? config.CACHE_DURATION_IN_MINUTES)

        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(expiryMins),
          cache: content,
          cacheType: 'banner_sub'
        }, {
          root: true
        })

        return content
      } catch (error) {
        return error
      }
    },

    async getNavigationMenuBySlug (payload) {
      try {
        const response = await ContentApi.GetNavigationMenuBySlug(payload.slug, this.version)

        if (payload.slug === 'footer') {
          useCacheStore().setCache({
            expires_at: CacheController.getTimeInXMinutes(Number(response.data.stories[0].content.cache_expiry)),
            cache: ContentController.formatCmsFooterContent(response.data.stories[0].content),
            cacheType: 'footer'
          }, {
            root: true
          })
        }

        return ContentController.formatCmsFooterContent(response.data.stories[0].content)
      } catch (error) {
        if (payload.slug === 'footer') {
          useCacheStore().setCache({
            expires_at: null,
            cache: footerDefaults,
            cacheType: 'footer'
          }, {
            root: true
          })
        }

        throw error
      }
    },

    async getSnippet (payload) {
      const response = await ContentApi.GetSnippet(payload, this.version)
      const stories = filterStories(response.data.stories).filter(x => x.full_slug === `snippets/${payload.path}/${x.slug}`)

      if (stories.length) {
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(stories[0].content.cache_expiry),
          cache: {
            name: stories[0].name,
            content: stories[0].content
          },
          path: `${payload.path}/${payload.id}`,
          cacheType: 'snippets'
        }, {
          root: true
        })
        return stories[0].content
      } else if (payload.fallback.length) {
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(config.CACHE_DURATION_IN_MINUTES),
          cache: {
            name: payload.name,
            content: {
              body: payload.fallback
            }
          },
          path: payload.path,
          cacheType: 'snippets'
        }, {
          root: true
        })
      }
    },

    async getSnippets (payload) {
      const response = await ContentApi.GetSnippet(payload, this.version)
      return response
    },

    async getIngredientUpsell (payload) {
      const response = await ContentApi.GetSnippet(payload)

      // Check if we will show on specific browser or device
      const stories = filterStories(response.data.stories)

      const story = stories?.[0]
      const upsell = story?.content

      const expiry = upsell ? upsell.cache_expiry : config.CACHE_DURATION_IN_MINUTES
      const cache = {
        name: story ? story.name : payload.name,
        content: upsell || { body: payload.fallback }
      }
      const path = story ? `${payload.path}/${payload.productId}` : payload.path

      if (upsell) {
        upsell.add_ingredients.data = upsell.add_ingredients.data.filter(x => x !== 'all')
        upsell.remove_ingredients.data = upsell.remove_ingredients.data.filter(x => x !== 'all')
      }

      if (story || payload.fallback.length) {
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(expiry),
          cache,
          path,
          cacheType: 'snippets'
        }, {
          root: true
        })
      }

      return upsell
    },

    async getBaseUpsell (payload) {
      const response = await ContentApi.GetSnippet(payload)

      // Check if we will show on specific browser or device
      const stories = filterStories(response.data.stories)

      const story = stories?.[0]
      const upsell = story?.content

      const expiry = upsell?.cache_expiry || config.CACHE_DURATION_IN_MINUTES
      const cache = {
        name: story ? story.name : payload.name,
        content: upsell || { body: payload.fallback }
      }
      const path = story && payload?.productId ? `${payload.path}/${payload.productId}` : payload.path

      if (story || payload.fallback.length) {
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(expiry),
          cache,
          path,
          cacheType: 'snippets'
        }, {
          root: true
        })
      }

      return upsell
    },

    async getStore (payload) {
      const response = await ContentApi.GetStore(payload, this.version)

      return response.data.stories
    },

    async getSpinner (payload) {
      const response = await ContentApi.GetSpinner(payload, this.version)

      // Check if we will show on specific browser or device
      const stories = filterStories(response.data.stories)
      const story = stories?.at(0)?.content

      if (story) {
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(Number(story.cache_expiry)),
          cache: story,
          cacheType: 'spinner'
        }, { root: true })
        return story
      } else {
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(5),
          cache: null,
          cacheType: 'spinner'
        }, { root: true })
      }
    },

    async getSplashScreen (payload) {
      const response = await ContentApi.GetSplashScreen(payload, this.version)

      // Check if we will show on specific browser or device
      const stories = filterStories(response.data.stories)

      const splash = stories?.at(0)

      if (splash) {
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(config.CACHE_DURATION_IN_MINUTES),
          cache: {
            name: splash.name,
            content: splash.content
          },
          cacheType: 'splash_screen'
        }, { root: true })

        return { name: splash.name, content: splash.content }
      }

      useCacheStore().setCache({
        expires_at: CacheController.getTimeInXMinutes(5),
        cache: null,
        cacheType: 'splash_screen'
      }, { root: true })
    },

    async getFilterIcons () {
      try {
      const response = await ContentApi.GetStory('icons/filter-icons')

      const content = response.data.story.content
      const expiryMins = Number(content?.cache_expiry ?? config.CACHE_DURATION_IN_MINUTES)

      useCacheStore().setCache({
        expires_at: CacheController.getTimeInXMinutes(expiryMins),
        cache: content.image,
        cacheType: 'filter_icons'
      }, {
        root: true
      })

      return content
      } catch (error) {
        console.error(error)
      }
    },

    async getStory (payload) {
      return await ContentApi.GetStory(payload.slug, this.version)
    },

    async getBlackListedUserAgents (payload) {
      const response = await ContentApi.GetBlackListedUserAgents(payload.slug, this.version)
      const blacklisted = response.data.datasource_entries
      this.blacklistedUserAgents = blacklisted
    },
    async getBlackListedIRPID (payload) {
      const response = await ContentApi.GetBlackListedIRPID(payload.slug, this.version)
      const blacklisted = response.data.datasource_entries
      this.blacklistedIRPID = blacklisted
    },
    async getDictionary () {
      const response = await ContentApi.GetDictionary(this.version)
      // Save Key Value Here!!
      if (response?.data.datasource_entries?.length) {
        const dictionary = {}
        for (const ds of Object.values(response.data.datasource_entries)) {
          dictionary[ds.name] = ds.value
        }
        // console.log('getDictionary - Save to cache')
        useCacheStore().setCache({
          expires_at: CacheController.getTimeInXMinutes(30),
          cache: dictionary, // UPdate this to cache object,
          cacheType: 'dictionary'
        }, { root: true })
        return dictionary
      }
    },
    async getSpinTheWheel (payload) {
      const response = await ContentApi.GetSpinTheWheel(payload)

       // Check if we will show on specific browser or device
      const stories = filterStories(response.data.stories, ['content', 'schedule', '0'])
      const story = stories?.at(0)?.content
      return story
    },
    async getOffersCategories () {
      const response = await ContentApi.GetOffersCategories('offers-categories')
      return response.data.datasource_entries
    },
    async getBottomNavBySlug (slug) {
      const cacheType = 'bottom_nav'
      try {
        const newSlug = slug + '/variation-' + window.abtests['cctt-14']
        const res = await ContentApi.GetNavigationMenuBySlug(newSlug, this.version)

        const navigationItems = res.data?.stories[0].content?.navigation_items
        const expiry = res.data?.stories[0].content?.cache_expiry ?? config.CACHE_DURATION_IN_MINUTES

        if (!navigationItems || navigationItems?.length < 0) {
          console.error('navigation items are empty')
          return
        }

        const navs = navigationItems.map((nav) => {
          const icons = {}
          nav.icons.forEach((icon) => {
            icons[getIconFromFileName(icon.filename)] = replaceImageDomain(icon.filename)
          })
          const to = nav.url?.url ?? '/'
          return {
            name: nav.title.charAt(0).toUpperCase() + nav.title.slice(1),
            to,
            icons,
            disabled: nav.disabled ?? false
          }
        })

        useCacheStore().setCache({
          cacheType,
          expires_at: CacheController.getTimeInXMinutes(expiry),
          cache: navs
        })
        return navs
      } catch (error) {
        useCacheStore().setCache({
          expires_at: null,
          cache: bottomNavDefaults,
          cacheType
        }, {
          root: true
        })

        throw (error)
      }
    },
    async getFeatureFlags () {
      const response = await ContentApi.GetFeatureFlags()
      return response.data.datasource_entries
    },
    async getMenuCategories (payload) {
      const { setCache } = useCacheStore()
      const response = await ContentApi.GetMenuCategories(payload, this.version)

      // Check if we will show on specific browser or device
      const stories = filterStories(response.data.stories)

      const content = stories.at(0)?.content
      const expiryMins = Number(content?.cache_expiry ?? config.CACHE_DURATION_IN_MINUTES)

      if (!content) {
        setCache({
          expires_at: CacheController.getTimeInXMinutes(expiryMins),
          cache: null,
          cacheType: 'menu_categories',
          path: payload.path
        }, {
          root: true
        })
        return
      }

      // Filter menu category items according to scheduling rules
      content.menu_categories = content.menu_categories.filter(menuCategory => filterScheduledItem(menuCategory, removeScheduleQueryPath(payload.query), ['schedule', '0']))

      setCache({
        expires_at: CacheController.getTimeInXMinutes(expiryMins),
        cache: content,
        cacheType: 'menu_categories',
        path: payload.path
      }, {
        root: true
      })

      return content
    }
  }
})
