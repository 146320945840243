/**
 * Generally speaking, this module governs and controls states for UI components
 * that are considered global. Global means two things in this case:
 * 1- There's a single instance of each UI component
 * 2- The component reacting to its state lives on a layout (Default.vue and/or Bare.vue)
 */

import { defineStore } from 'pinia'

import {
  uiAlertDefaults as ALERT_DEFAULTS,
  uiSnackbarDefaults as SNACKBAR_DEFAULTS,
  uiSpinnerDefaults as SPINNER_DEFAULTS,
  uiMinimumSpendModalDefaults as MINIMUM_SPEND_DEFAULTS
} from '@/stores/helpers/ui.defaults'

export const useUiStore = defineStore('ui', {
  state: () => ({
    alert: structuredClone(ALERT_DEFAULTS),
    snackbar: structuredClone(SNACKBAR_DEFAULTS),
    snackbarTimer: null,
    spinner: structuredClone(SPINNER_DEFAULTS),
    minimumSpendModal: structuredClone(MINIMUM_SPEND_DEFAULTS),
    showBackdrop: false,
    debugMode: true,
    pageRefreshed: false,
    popover: {
      showLocalisationPopover: false,
      disableLocalisationPopover: false
    }
  }),

  getters: {
    showLocalisationPopover: state => state.popover.showLocalisationPopover,
    isLocalisationPopoverDisabled: state => state.popover.disableLocalisationPopover
  },

  actions: {
    triggerAlert (payload) {
      this.alert = {
        ...ALERT_DEFAULTS,
        ...payload
      }
    },
    deactivateAlert () {
      this.alert = {
        ...ALERT_DEFAULTS
      }
    },
    async triggerSnackbar (payload) {
      // action needs to be async for precise flow control

      this.dismissSnackbar()
      this.snackbar = {
        ...SNACKBAR_DEFAULTS,
        ...payload
      }

      if (payload.autoClose || this.snackbar.autoClose) {
        this.snackbarTimer = setTimeout(() => {
          this.dismissSnackbar()
        }, this.snackbar.autoCloseDuration)
      }
    },
    dismissSnackbar () {
      clearTimeout(this.snackbarTimer)
      this.snackbarTimer = null
      this.snackbar = {
        ...SNACKBAR_DEFAULTS
      }
    },
    triggerSpinner (payload) {
      if (!payload.message && payload.loading) {
        payload.message = this.spinner.message
      }

      this.spinner = {
        ...SPINNER_DEFAULTS,
        ...payload
      }
    },
    triggerMinimumSpendModal (payload) {
      this.minimumSpendModal = {
        ...MINIMUM_SPEND_DEFAULTS,
        ...payload
      }
    },
    dismissMinimumSpendModal () {
      this.minimumSpendModal = {
        ...MINIMUM_SPEND_DEFAULTS
      }
    },
    setShowBackdrop (payload) {
      this.showBackdrop = payload
    },
    setDebugMode (payload) {
      this.debugMode = payload
    },
    setPageRefreshed (payload) {
      this.pageRefreshed = payload
    },
    setPopover (payload) {
      this.popover = payload
    },
    setShowLocalisationPopover (payload) {
      this.popover.showLocalisationPopover = payload
    },
    disableLocalisationPopover () {
      this.popover.disableLocalisationPopover = true
    }
  }
})
